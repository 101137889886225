


.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59vh;
}

.centered-panel {
  padding: 50px;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 50%;
  text-align: center;
}

.centered-container {
  background-color: #f4f4f4;
}

.centered-panel {
  background-color: #ffffff;
}

@media (max-width: 1124px) {
  .centered-container {
    display: initial;
    justify-content: initial;
    align-items: initial;
    height: initial;
  }

  .centered-panel {
    padding: initial;
    border-radius: initial;
    box-shadow: initial;
    max-width:initial;
    width: initial;
    text-align: initial;
  }

  .centered-panel-text {
    text-align: center;
    margin-bottom: 20px;
  }
  .centered-container {
    background-color: inherit;
  }
  .centered-panel {
    background-color: inherit;
  }
}

button.p-button {
  padding: 10px 30px;
  border-radius: 10px;
  background: #235bb0;
}

.error-msg {
  color: indianred;
}
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002752;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
  padding: 30px 30px;
  flex-direction: column;
}

.header-logo {
  height: 80px;
}

.app-footer {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: auto;
    background-color: #002752;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.company-container {
    height: 35vh;
}


.store-panel-text {
    padding: 8px 10px;
    font-size: 16px;
    background-color: #edf3f6;
    color: #003d50;
}

.app-footer p {
    font-size: 1.0rem;
    color: #eeeff4;
    margin-top: 10px;
}

.store-panel {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.store-badge {
    margin: 10px;
}

.store-badge img {
    width: 200px;
}


.centered-panel {
    max-width: initial;
    width: initial;
}

.loans-input-group {
    padding: 10px;
    text-align: right;
}

@media (min-width: 1125px) {
    .loans-input-group {
        justify-content: end;
    }
    .p-datatable .p-inputnumber-input {
        max-width: 95px;
    }
}

@media (max-width: 1124px) {
    .minimum-payment-column {
        display: none;
    }

    .loans-input-group {
         flex-direction: row;
         justify-content: center;
     }
    .p-datatable .p-inputnumber-input {
        width: 100%;
    }
}


.loans-input-group .p-button + .p-button {
    margin-left: 10px;
}

.loans-input-group button.cancel {
    background: #d9534f;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loans-input-group button:hover.cancel {
    background-color: #d2322d;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.12);
}

.loans-input-group button:active.cancel {
    background-color: #c9302c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.loans-input-group button:focus-visible.cancel {
    box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.5);
}


.customerId {
    font-size: 16px;
    color: #003d50;
    font-weight: bold;
    text-align: left;
}


@media (max-width: 1124px) {
    .error-msg {
        text-align: center;
        margin: 0 0 20px;
    }
}

.landing-input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 1125px) {
    .landing-input-group {
        flex-direction: row;
        gap: 20px;
    }
}

@media (max-width: 1124px) {
    .landing-input-group {
        margin-top: 20px;
        flex-direction: column;
    }
}

.landing-input-group .customerId-input {
    width: 100%;
}

.landing-input-group button.p-button {
    vertical-align: middle;
    width: 200px;
}

@media (max-width: 1124px) {

    .landing-input-group .customerId-input {
        width: 365px;
    }

    .landing-input-group button.p-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.landing-input-group .error-msg {
    text-align: left;
    margin-left: 15px;
}


@media (max-width: 1124px) {
    .error-msg {
        text-align: center;
        margin: 0 0 20px;
    }
}